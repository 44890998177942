import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import PhonePe_Logo from "../../images/PhonePe_horizontal.png";
import { useState } from "react";

const Footer = () => {
  const navigate = useNavigate();
  // let url

  // useState(() => {
  //   const { pathname } = window.location
  //   url = pathname
  // }, []);

  // console.log({url});

  return (
    <>
      <footer class="footer">
        <div class="content has-text-centered container">
          <p>
            <p style={{ textAlign: "left" }}>
              {/* contact us: contact@bookiteazy.com */}
              {/* <hr></hr> */}
              <p style={{ textAlign: "left", lineHeight: "1.4" }}>
                Eazycarts.online - a product of&nbsp;
                <strong>
                  <a href="https://linkedin.com/company/bookiteazy-technologies">
                    Bookiteazy Technologies
                  </a>
                </strong>{" "}
                <p style={{ textAlign: "right" }}>India</p>
              </p>
            </p>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                // bottom: "0",
                position: "absolute",
                width: "100%",
                fontSize: "12px",
                padding: "10em 0 0 0",
              }}
            >
              Copyright © 2024 Bookiteazy CRYG Technologies LLP. All rights
              reserved.
            </span>
            <img
              src={Logo}
              className="has-text-centered"
              style={{
                margin: "0 auto",
                display: "block",
                width: "18em",
              }}
            />
            {/* {url === "/home" && (
              <p style={{ textAlign: "left", color: "#4a4a4a" }}>
                <div style={{ display: "block" }}>
                  <p>
                    We are using India's most trusted Payment Gateway. 100%
                    SECURE
                  </p>{" "}
                  <img src={PhonePe_Logo} style={{ width: "7em" }} />
                </div>
                <a
                  style={{ color: "#4a4a4a" }}
                  onClick={() => navigate("/privacy-policy")}
                >
                  <u>Privacy Policy</u>
                  <img
                    src={Logo}
                    className="has-text-centered"
                    style={{
                      margin: "0 auto",
                      display: "block",
                      width: "25em",
                    }}
                  />
                </a>
              </p>
            )} */}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
