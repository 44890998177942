const dateChecker = (dateString) => {
  const currentDate = new Date();
  const [day, month, year] = dateString.match(/\d+/g).map(Number);

  const dueDate = new Date(year, month - 1, day);
  const isToday = currentDate.toDateString() === dueDate.toDateString();
  const isPastDue = dueDate < currentDate && !isToday;

  return { isDue: isToday || isPastDue, isMissed: isPastDue };
};

export default dateChecker;
