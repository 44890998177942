import { useEffect, useState } from "react";

// 0 for logged out
// 1 logged in

const useCheckIsLoggedIn = () => {
  const [isLogged, setIsLoggedIn] = useState(0);
  const userLogged = localStorage.getItem("isLoggedIn");

  function setIsLogged(n) {
    setIsLoggedIn(n)
    if(n === 0) localStorage.setItem("isLoggedIn", 0)
    if(n === 1) localStorage.setItem("isLoggedIn", 1)
  }

//   useEffect(() => {
//     if (!userLogged) setIsLogged(0);
//     if (userLogged) setIsLogged(1);
//   }, []);

  return {
    isLogged,
    setIsLogged,
  };
};

export default useCheckIsLoggedIn;
