import Logo from "../../images/logo.png";
import { useEffect, useState } from "react";
// import "../App.css";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        {/* Header */}
        <nav class="navbar logo-nav-padding">
          <div class="container">
            <div id="navMenu" class="navbar navbar-menu-open">
              <div class="navbar-start">
                <img src={Logo} alt="Logo" />
              </div>

              <div class="navbar-end">
                <div class="navbar-item">
                  <div class="buttons">
                    <a
                      class="button is-dark"
                      style={{ borderRadius: "2em" }}
                      onClick={() => navigate("/home")}
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div class="container has-text-centered" style={{ padding: "3em 0" }}>
        <h1 style={{ fontSize: "xx-large" }}>
          <b>
            <u>Privacy Policy for "Eazycarts.online"</u>
          </b>
        </h1>
        <br></br>
        <p style={{ textAlign: "justify" }}>
          Effective Date: 14-03-2024
          <br></br>
          <br></br>
          Eazycarts.online is owned and operated by Bookiteazy CRYG Technologies
          LLP. This Privacy Policy explains how Eazycarts.online is owned and
          operated by Bookiteazy CRYG Technologies LLP ("we," "our," or "us")
          collects, uses, and protects the information you provide when using
          our online appointment system platform and associated services.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>1. Information We Collect</b>
        </h3>
        <p style={{ textAlign: "justify" }}>
          When you click on submit after filling below details as a
          doctor/dentist/therapist on our platform, we collect the following
          information:
          <ul style={{ textAlign: "justify", textDecoration: "underline" }}>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Email</li>
            <li>Number</li>
          </ul>
          Additionally, upon subscription payment, we may request further
          details to enhance your experience on our platform. Any additional
          information will be collected with your consent.
        </p>
        <br></br>

        <h3 style={{ textAlign: "start" }}>
          <b>2. Use of Collected Information</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          We use the collected information for the following purposes:
          <ul style={{ textAlign: "justify", textDecoration: "underline" }}>
            <li>To create and manage your account.</li>
            <li>To provide and personalize our services.</li>
            <li>
              To communicate with you about your account and our services.
            </li>
            <li>To process subscription payments.</li>
          </ul>
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>3. Non-Refundable Subscription Payments</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          Amounts paid for subscription services are non-refundable. By
          completing the subscription payment, you acknowledge and agree to the
          non-refundable nature of these payments.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>4. Refund Requests</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          If you wish to request a refund, please raise a request by emailing us
          at contact@bookiteazy.com. We will contact you to discuss your
          request; however, we cannot guarantee a refund. Refund decisions will
          be made on a case-by-case basis after careful consideration.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>5. Data Security</b>
        </h3>

        <p style={{ textAlign: "justify" }}>
          We implement appropriate security measures to protect the
          confidentiality, integrity, and availability of your information.
          However, please be aware that no method of transmission over the
          internet or electronic storage is 100% secure.
        </p>
        <br></br>
        <h3 style={{ textAlign: "start" }}>
          <b>6. Changes to Privacy Policy</b>
        </h3>
        <p style={{ textAlign: "justify" }}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices. You will be notified of any significant updates via
          email or platform announcements.
        </p>
        <br></br>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
