import React, { useEffect, useState } from "react";
import Header from "../../components/headerFooter/header/index";
import Footer from "../../components/headerFooter/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import LoginForm from "../auth/Login";
import useCheckIsLoggedIn from "../hooks/useCheckLoggedIn";
import useCheckToken from "../hooks/useCheckToken";
import { Container, Grid } from "@mui/material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

const customer = localStorage.getItem("customer")

const MainView = ({ children }) => {
  const { isLogged } = useCheckIsLoggedIn();
  const { isValid } = useCheckToken();

  const navigate = useNavigate();

  const actions = [
    {
      icon: <AddShoppingCartIcon />,
      name: "Add Item",
      operation: "AddItem",
    },
    {
      icon: <SpaceDashboardIcon />,
      name: "Dashboard",
      operation: "Dashboard",
    },
  ];

  const handleSpeedDialRoute = (e, operation) => {
    e.preventDefault();
    if (operation === "AddItem") {
      navigate("/app/add-item", {
        state: {
          add: "fromDashboard",
        },
      });
    } else if (operation === "Dashboard") {
      navigate("/app");
    }
    // else if (operation === "List") {
    //   navigate("/app/add-item");
    // }
  };
  
  if (isValid === 0 && isLogged === 0) {
    return <LoginForm />;
  } else if (isValid === 0 && isLogged === 1) {
    return <LoginForm />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Header customer={customer} />
        <Outlet />
        {/* <Footer /> */}
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: "4em", right: "4em" }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={(e) => handleSpeedDialRoute(e, action.operation)}
            />
          ))}
        </SpeedDial>
      </Container>
    </>
  );
};

export default MainView;
