import { useEffect, useState } from "react";
import ItemCard from "../ItemCard";
import useSectorData from "../hooks/useSectorData";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { getItems, itemCompleted } from "../../API";
import { useLocation } from "react-router-dom";
import dateChecker from "../utils/dateChecker";
import { filterDates } from "../utils/filterDates";

const SectorOutlet = ({ filter, sector, priority, startDate, endDate }) => {
  console.log({ priority });
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [sets, setSets] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    const getItemsList = async () => {
      const path = pathname.split("/");
      const sectorFromPath = path[path.length - 1];
      const smSectorName = sectorFromPath?.toLowerCase();
      const result = await getItems({ sector: smSectorName });
      if (result.success) {
        const itemsdates = result?.data?.items;
        const dateMod = itemsdates.map((date) => {
          const [day, month, year] = date.six.match(/\d+/g).map(Number);
          return {
            ...date,
            six: `${day}/${month}/${year}`,
          };
        });
        setItems(dateMod);
      }
    };

    getItemsList();
  }, []);

  useEffect(() => {
    const handleFilterChange = (filter) => {
      let newFilteredItems = items;
      if (filter === "Pending") {
        newFilteredItems = items.filter((item) => item.pending === "y");
      } else if (filter === "Completed") {
        newFilteredItems = items.filter((item) => item.pending === "n");
      } else if (filter === "Missed") {
        newFilteredItems = items.filter((item) => {
          console.log(item.orderBy);
          const dateString = item.six.split(" ")[0];
          return dateChecker(dateString)?.isMissed;
        });
      }

      if (priority) {
        if (priority === "low") {
          newFilteredItems = newFilteredItems.filter(
            (item) => item.five === "low"
          );
          setFilteredItems(newFilteredItems);
        } else if (priority === "medium") {
          newFilteredItems = newFilteredItems.filter(
            (item) => item.five === "medium"
          );
          setFilteredItems(newFilteredItems);
        } else if (priority === "high") {
          newFilteredItems = newFilteredItems.filter(
            (item) => item.five === "high"
          );
          setFilteredItems(newFilteredItems);
        }
      } else {
        setFilteredItems(newFilteredItems);
      }
    };

    handleFilterChange(filter);

    console.log({ items });
    if (startDate !== "" && endDate !== "") {
      const dates = filterDates(filteredItems, startDate, endDate);
    }
  }, [filter, priority]);

  useEffect(() => {
    const createSets = () => {
      const newSets = [];
      for (let i = 0; i < filteredItems.length; i += 5) {
        newSets.push(filteredItems.slice(i, i + 5));
      }
      setSets(newSets);
    };

    createSets();
  }, [filteredItems]);
  //   const [completed, setCompleted] = useState(false);
  //   const [filteredData, setFilteredData] = useState({
  //     allItems: [],
  //     completedItems: [],
  //     pendingItems: [],
  //   });
  //   const [items, setItems] = useState([]);
  //   const { pathname } = useLocation();

  //   useEffect(() => {
  //     const getItemsList = async () => {
  //       const path = pathname.split("/");
  //       const sectorFromPath = path[path.length - 1];
  //       const smSectorName = sectorFromPath?.toLowerCase();
  //       const result = await getItems({ sector: smSectorName });
  //       if (result.success) {
  //         setFilteredData({
  //           ...filteredData,
  //           allItems: result?.data?.items,
  //         });
  //         // setItems();
  //       }
  //     };

  //     getItemsList();
  //   }, []);

  //   useEffect(() => {
  //     const completedItems = items.filter((set) => {
  //       return set.every((item) => item.pending === "n");
  //     });
  //     setFilteredData({
  //       ...filteredData,
  //       completedItems: completedItems,
  //     });
  //   }, [filter]);

  //   const splitItems = (array) => {
  //     if (array) {
  //       const sets = [];
  //       const numSets = Math.ceil(array.length / 5);

  //       for (let i = 0; i < numSets; i++) {
  //         const startIndex = i * 5;
  //         const endIndex = Math.min(startIndex + 5, array.length);
  //         const set = array.slice(startIndex, endIndex);
  //         sets.push(set);
  //       }

  //       return sets;
  //     }
  //     return [];
  //   };

  //   const sets = splitItems(filteredData.all);
  //   console.log({ sets });

  if (filter === "") {
    return (
      <>
        <div
          style={{
            margin: "5em 0em",
            fontSize: "2em",
            border: "1px solid lightgray",
            padding: "1em",
            color: "lightgray",
          }}
        >
          <SentimentDissatisfiedOutlinedIcon /> Choose Filter eg. "All Items",
          "Pending", etc.
        </div>
      </>
    );
  }

  const markCompleted = async (markedItemId) => {
    // setCompleted(!completed);
    // console.log({ markedItemId });
    const confirmAction = window.confirm("Sure completed?");
    if (confirmAction) {
      const updateItems = filteredItems.map((item) =>
        item.itemId === markedItemId
          ? { ...item, pending: item.pending === "y" ? "n" : "y" }
          : item
      );
      setItems(updateItems);
      const sectorName = sector.toLowerCase();
      const result = await itemCompleted({
        sector: sectorName,
        itemId: markedItemId,
      });
      if (result.success) {
        window.location.reload();
      }
    }
  };

  function filterDate(date) {
    const dateTimeString = date;
    const dueDate = dateTimeString.split(" ")[0];
    return dueDate;
  }

  console.log({ filteredItems });

  return (
    <>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-3">
          {/*  Most Recents */}
          <div class="tile">
            <div class="tile is-parent">
              <section className="mt-6">
                <div className="title">{filter}</div>
                <div class="tile is-ancestor">
                  <div class="tile is-vertical is-4">
                    {sets.length === 0 ? (
                      <div
                        style={{
                          margin: "1em 0em 0em 0em",
                          fontSize: "2em",
                          border: "1px solid lightgray",
                          padding: "0.5em",
                          color: "lightgray",
                          width: "max-content",
                        }}
                      >
                        <SentimentDissatisfiedOutlinedIcon /> No Items Found!
                      </div>
                    ) : (
                      Array.isArray(sets) &&
                      sets.length > 0 &&
                      sets.map((set) => (
                        <div class="tile">
                          {set.length > 0 &&
                            set.map((item) => (
                              <div class="tile is-parent">
                                <ItemCard
                                  key={item.createdAt}
                                  product={item.one}
                                  brand={item.two}
                                  price={item.four}
                                  orderBy={filterDate(item.six)}
                                  qtyDues={item.three}
                                  sector={sector}
                                  itemId={item.itemId}
                                  pending={item.pending}
                                  markCompleted={markCompleted}
                                />
                              </div>
                            ))}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectorOutlet;
