import { Grid } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ShieldIcon from "@mui/icons-material/Shield";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Logo from "../../../images/logo.png";
// import Logo from "../../../images/logoTEMP.png";
import Chintan from "../../../images/chintan.png";
import { Logout } from "../../../API";
import { useNavigate } from "react-router-dom";
import useCheckIsLoggedIn from "../../hooks/useCheckLoggedIn";
import useCheckToken from "../../hooks/useCheckToken";

const MainHeader = ({ customer }) => {
  const { isLogged, setIsLogged } = useCheckIsLoggedIn();
  const { isValid } = useCheckToken();

  const navigate = useNavigate();

  const handleLogout = async () => {
    const result = await Logout();

    if (result.success) {
      setIsLogged(0);
      navigate("/");
      localStorage.removeItem("isLoggedIn");
    } else if (!result.success) {
      alert("Oops, Try again!");
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ margin: "0.5em", marginBottom: "13em" }}
      >
        <Grid xs="auto">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "12em", marginTop: "1em" }}
            onClick={() => navigate("/app")}
            className="LogoHover"
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs direction="row">
          <Chip
            variant="outlined"
            color="neutral"
            size="lg"
            // startDecorator={<Avatar size="sm" src={Chintan} />}
            endDecorator={<ShieldIcon fontSize="md" />}
            onClick={() => navigate("/app/user")}
            style={{
              display: " flex",
              margin: "0 auto",
            }}
          >
           "My Account"
          </Chip>
          <Chip
            variant="plain"
            style={{
              display: "flex",
              margin: "0 auto",
              backgroundColor: "white",
            }}
            endDecorator={
              <PowerSettingsNewIcon
                fontSize="md"
                style={{ color: "green", background: "white" }}
              />
            }
            onClick={() => handleLogout()}
          ></Chip>
        </Grid>
      </Grid>
    </>
  );
};

export default MainHeader;
