import {
  Button,
  Card,
  CardContent,
  Container,
  ToggleButtonGroup,
  Typography,
} from "@mui/joy";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useSectorData from "../hooks/useSectorData";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Foods from "../../images/foods_eazy.png";
import Grocery from "../../images/grocery_eazy.png";
import Medicines from "../../images/medicines_eazy.png";
import Payments from "../../images/payments_eazy.png";
import Analytical from "../../images/analytical.png";
import GlanceView from "../../images/glance.png";
import History from "../../images/history.png";
import Pririoty from "../../images/priority.png";
import FeatureIcon from "../../images/featuresIcon.png";
import PaymentsFree from "../../images/paymentsfree.jpg";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";

const FeaturesOutlet = () => {
  const features = [
    {
      img: Analytical,
      title: "Analytical",
      icon: null,
      subtitle: "Keep track on expenses/items",
      descTitle: "",
      descSubTitle: "",
    },
    {
      img: GlanceView,
      title: "Glance View",
      subtitle: "Eye on recents/priorities",
      descTitle: "",
      descSubTitle: "",
    },
    {
      img: History,
      title: "History",
      subtitle: "Maintained history",
      descTitle: "",
      descSubTitle: "",
    },
    {
      img: Pririoty,
      title: "Priorities",
      subtitle: "Never miss priorities",
      descTitle: "",
      descSubTitle: "",
    },
  ];
  const [filterLoading, setFilterLoading] = useState(false);
  const [activeFeature, setActiveFeature] = useState({
    img: Analytical,
    title: "Analytical",
    icon: null,
    subtitle: "Keep track on expenses/items",
    descTitle: "",
    descSubTitle: "",
  });
  const [activeImage, setActiveImage] = useState("");

  const handleFilters = (feature) => {
    setActiveFeature({
      title: feature.title,
      subtitle: feature.subtitle,
      desc_title: feature.descTitle,
      desc_subtitle: feature.descSubTitle,
      img: feature.img,
      icon: feature.icon,
    });
  };

  return (
    <>
      <Container>
        <Container
          sx={{
            // width: 300,
            mx: "auto", // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            display: "ruby-text",
            // boxShadow: "md",
          }}
        >
         
          <h1 class="title is-1 mt-6 text-centered has-text-centered mb-2 has-text-weight-bold">
          <span className="underline"> <img
            src={FeatureIcon}
            alt="FeaturesIcon"
            style={{
              width: "1em",
            }}
            // onClick={() => navigate("/app")}
            className="LogoHover"
          />Features</span>
          </h1>
        </Container>
        <div className="container level">
          <p class="buttons">
            {Array.isArray(features) &&
              features.map((feature) => {
                return (
                  <>
                    <Card
                      size="sm"
                      className="mr-2 mb-1"
                      variant={
                        activeFeature.title === feature.title
                          ? "outlined"
                          : "soft"
                      }
                      onClick={() => handleFilters(feature)}
                    >
                      <CardContent>
                        <Typography level="title-md">
                          {feature.title}
                        </Typography>
                        <Typography>{feature.subtitle}</Typography>
                        {/* {activeFilter === feature.title ? (
                          <img src={feature.img} />
                        ) : null} */}
                      </CardContent>
                    </Card>
                  </>
                );
              })}
          </p>
          <Card
            style={{
              width: "100%",
            }}
            className="mr-2 mb-6"
            variant="outlined"
            // onClick={() => handleFilters(`${filter.title}`)}
          >
            <CardContent className="has-text-centered">
              {activeFeature.img ? <img src={activeFeature.img} /> : null}
              <Typography className="title is-1 mt-2" level="title-lg">
                {activeFeature.desc_title}
              </Typography>
              <Typography className="title is-2" level="body-sm">
                {activeFeature.desc_SubTitle}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <h1
          class="title is-1 text-centered has-text-centered mb-2 has-text-weight-bold"
          style={{
            marginTop: "4rem",
          }}
        >
          <span className="underline"><svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="60"
            viewBox="0 0 576 512"
          >
            <path d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V205.3L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160H384z" />
          </svg>{" "}
          Benefits</span>
        </h1>
        <div
          style={{
            marginTop: "4rem",
            marginBottom: "4rem",
          }}
        >
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-8">
              <div class="tile">
                <div class="tile is-parent is-vertical">
                  <article class="tile is-child notification">
                    <p class="title">Save Time</p>
                    <p class="subtitle">
                      Spend less time on mundane tasks and more time on what
                      matters.
                    </p>
                  </article>
                </div>
                <div class="tile is-parent">
                  <article class="tile is-child notification">
                    <p class="title">Reduce Stress</p>
                    <p class="subtitle">
                      Eliminate the hassle of forgotten items or missed
                      payments.
                    </p>
                    <figure class="image is-4by3">
                      <img style={{ opacity: "0.9" }} src={PaymentsFree} />
                    </figure>
                  </article>
                </div>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child notification">
                  <p class="title">Stay Organized</p>
                  <p class="subtitle">
                    Keep track of all your shopping and payments in one
                    centralized location.
                  </p>
                  <div class="content"></div>
                </article>
              </div>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification">
                <div class="content">
                  <p class="title">Enhance Productivity</p>
                  <p class="subtitle">
                    Increase efficiency by streamlining your shopping and bill
                    payment processes.
                  </p>
                  <div class="content"></div>
                </div>
              </article>
            </div>
          </div>
        </div>

        <h1
          class="title is-1 text-centered has-text-centered mb-2 has-text-weight-bold"
          style={{
            marginTop: "6rem",
          }}
        >
          <span className="underline"><ConnectWithoutContactIcon fontSize="lg" /> Why Choose Us?</span>
        </h1>
        <div
          style={{
            marginTop: "3rem",
            marginBottom: "4rem",
          }}
        >
          <article class="message is-dark">
            <div class="message-body">
              {" "}
              <LaptopMacIcon />
              <strong> User-Friendly Interface</strong> Our intuitive design
              makes managing carts a breeze.
            </div>
          </article>
          <article class="message is-dark">
            <div class="message-body">
              <AutoFixHighIcon />
              <strong> Continuous Improvement</strong> We're committed to
              enhancing our app based on user feedback. Makes managing carts a
              breeze.
            </div>
          </article>
          {/* <article class="message is-dark">
            <div class="message-body">
              <AutoAwesomeIcon />
              <strong> Pocket-Budget friendly</strong> Pay half your monthly
              coffee/tea costs.
            </div>
          </article> */}
            <article class="message is-dark">
            <div class="message-body">
              <AutoAwesomeIcon />
              <strong> Free for early users</strong> Currently it's free.
            </div>
          </article>
        </div>
      </Container>
    </>
  );
};

export default FeaturesOutlet;
