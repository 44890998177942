import { Tooltip } from "@mui/material";

export default function limitText(text, text_length) {
  const allow_till = text_length - 1;
  if (text?.length > text_length) {
    return (
      <Tooltip title={text} placement="bottom-end">
        <span>{`${text.substring(0, allow_till)}...`}</span>
      </Tooltip>
    );
  } else {
    return <span>{text}</span>;
  }
}
