export function filterDates(dateList, startDateStr, endDateStr) {
  var startDate = new Date(startDateStr);
  var endDate = new Date(endDateStr);

  var filteredDates = [];

  dateList.forEach(function (dateStr) {
    var date = new Date(dateStr);
    if (date >= startDate && date <= endDate) {
      filteredDates.push(dateStr);
    }
  });

  return filteredDates;
}
