import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { Tooltip } from "@mui/material";
import limitText from "./utils/LimitText";
import dateChecker from "./utils/dateChecker";

export default function ItemCard({
  pending,
  markCompleted,
  product,
  price,
  orderBy,
  brand,
  qtyDues,
  sector,
  itemId,
}) {
  const { isDue, isMissed } = dateChecker(orderBy);

  const handleMark = () => {
    markCompleted(itemId);
  };
  return (
    <Card
      orientation="horizontal"
      variant="outlined"
      sx={{ width: 260, height: "9em", width: "17em" }}
      key={itemId}
    >
      <CardOverflow>
        {/* <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90"
            srcSet="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90&dpr=2 2x"
            loading="lazy"
            alt=""
          />
        </AspectRatio> */}
      </CardOverflow>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
          {limitText(product, 20)}
        </Typography>
        <Typography level="body-sm">{limitText(brand, 20)}</Typography>
        <Typography level="body-sm">
          {price && "₹"} {price}
        </Typography>
        <Typography level="body-sm">
          {qtyDues && sector === "Payments" ? "Dues: " : "Quantity: "}
          {qtyDues}
        </Typography>
        <button
          size="sm"
          //   class={completed ? "button is-success" : "button"}
          className="button"
          onClick={handleMark}
          disabled={isMissed}
        >
          {isMissed ? null : pending === "y" ? null : (
            <span class="icon is-small">
              <i class="fas fa-check"></i>
            </span>
          )}

          <span>
            {isMissed
              ? "MISSED"
              : pending === "y"
              ? "Mark Completed"
              : "Completed"}
          </span>
        </button>
      </CardContent>
      <CardOverflow
        variant="soft"
        color="primary"
        sx={{
          px: 0.2,
          writingMode: "vertical-rl",
          justifyContent: "center",
          fontSize: "xs",
          fontWeight: "xl",
          letterSpacing: "1px",
          textTransform: "capitalize",
          borderLeft: "1px solid",
          borderColor: "divider",
          color: isDue ? "red" : "green",
          textAlign: "center",
        }}
      >
        <span>
          {orderBy} <br></br>
          <span>Order Due</span>
        </span>
      </CardOverflow>
    </Card>
  );
}
