import { useLocation, useParams } from "react-router-dom";
import SectorHeader from "./SectorHeader";
import SectorOutlet from "./SectorOutlet";

const Sector = ({ name }) => {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const sectorName = path[path.length - 1];
  const sector = sectorName.charAt(0).toUpperCase() + sectorName.slice(1);

  return (
    <>
      <SectorHeader name={sector} />
      {/* <SectorOutlet /> */}
    </>
  );
};

export default Sector;
