import React, { useEffect, useState } from "react";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { Sheet } from "@mui/joy";
import { Logout, getUserAccount } from "../API";
import useCheckIsLoggedIn from "./hooks/useCheckLoggedIn";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/joy/Chip";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const UserAccount = () => {
  const { setIsLogged } = useCheckIsLoggedIn();
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getUser() {
      try {
        const response = await fetch(
          "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/user",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        const result = await response.json();

        console.log({ result });
        if (result?.user) {
          setUser(result.user);
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    }
    getUser();
  }, []);

  const handleLogout = async () => {
    const result = await Logout();

    if (result.success) {
      setIsLogged(0);
      navigate("/");
      localStorage.removeItem("isLoggedIn");
    } else if (!result.success) {
      alert("Oops, Try again!");
    }
  };

  return (
    <>
      {!user ? (
        <Sheet
          sx={{
            width: 300,
            mx: "auto", // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            boxShadow: "md",
          }}
          variant="outlined"
        >
          Fetching USER DETAILS...
        </Sheet>
      ) : (
        <Sheet
          sx={{
            width: 300,
            mx: "auto", // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            boxShadow: "md",
          }}
          variant="outlined"
        >
          <div>
            <Typography level="h4" component="h1">
              <b>Hi, {user?.fullName}!</b>
            </Typography>
            <Typography level="h5">{user?.email}</Typography>
            <Typography level="h5" className="mb-4">
              {user?.number}
            </Typography>
            <Typography level="h5">Profession: {user?.professional}</Typography>
            <Typography level="h5">Subscription: {user?.plan}</Typography>
            <div>
              <Typography
                level="h6"
                style={{
                  fontSize: "13px",
                  margin: "1em 0 0 0",
                  color: "lightslategray",
                }}
                className="mb-6"
              >
                since {user?.onboardedAt}
              </Typography>

              <Chip
                variant="plain"
                style={{
                  display: "inline-flex",
                  margin: "0 auto",
                  backgroundColor: "white",
                  lineHeight: "2.5",
                }}
                onClick={() => handleLogout()}
                endDecorator={
                  <PowerSettingsNewIcon
                    fontSize="md"
                    style={{ color: "green", background: "white" }}
                  />
                }
              >
                Logout{" "}
              </Chip>

              <Typography
                level="h6"
                style={{
                  fontSize: "13px",
                  margin: "1em 0 0 0",
                  color: "lightslategray",
                  float: "inline-end",
                }}
                className="mt-2"
              >
                App Version: 1.0.0
              </Typography>
            </div>
          </div>
        </Sheet>
      )}
    </>
  );
};

export default UserAccount;
