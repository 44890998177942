import { FormControl, FormLabel, Input, Stack } from "@mui/joy";
import { useRef, useState } from "react";

const FieldDate = ({ name, label, onChange }) => {
  const inputRef = useRef(null);

  const [input, setInput] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput(event.target.value);
    console.log({ value });
    const dateValue = formatDate(value);
    console.log({ dateValue });

    onChange(name, dateValue);
  };

  const formatDate = (dateString) => {
    // Split the date string by '-'
    const parts = dateString.split("-");
    // Rearrange the parts and join with '/'
    return parts[2] + "/" + parts[1] + "/" + parts[0];
  };

  return (
    <FormControl>
      <Stack
        sx={{ minWidth: 300 }}
        style={{
          width: "13em",
        }}
      >
        <FormLabel>{label ? label : "Date"}</FormLabel>
        <Input
          style={{
            width: "13em",
          }}
          type="date"
          name={name}
          value={input}
          onChange={handleChange}
          //   inputProps={{ value: formatDate(input) }}
          // slotProps={{
          //   input: {
          //     min: "2018-06-07",
          //     max: "2018-06-14",
          //   },
          // }}
        />
      </Stack>
    </FormControl>
  );
};

export default FieldDate;
