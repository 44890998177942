import { useEffect, useState } from "react";

// 0 invalid/missing token
// 1 valid token

const useCheckToken = () => {
  const [isValid, setIsValid] = useState(0);

  useEffect(() => {
    let isMounted = true;

    async function checkToken() {
      try {
        const response = await fetch(
          "https://amethyst-adder-hem.cyclic.cloud/api/clients/checkToken",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        const result = await response.json();

        if (result?.error) {
          if (isMounted) {
            setIsValid(0);
          }
        } else if (result?.message === "valid token") {
          if (isMounted) {
            setIsValid(1);
          }
        }
      } catch (error) {
        setIsValid(0);
        console.error("Error checking token:", error);
      }
    }

    checkToken();

    return () => {
      isMounted = false;
    };
  }, []);

  return { isValid };
};

export default useCheckToken;
