import { FormControl, FormLabel, Input } from "@mui/joy";
import { forwardRef, useState } from "react";
import { NumericFormat } from "react-number-format";

const NumericFormatAdapter = forwardRef(function NumericFormatAdapter(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
});

const FieldAmountPrice = ({ name, sector, value, onChange }) => {

  const handleChange = (event) => {
    const {name, value} = event.target
    onChange(name, value)
  }
  return (
    <>
      <FormControl>
        <FormLabel>{sector === "Payments" ? "Amount" : "Price"}</FormLabel>
        <Input
        name={name}
          value={value}
          onChange={handleChange}
          placeholder={sector === "Payments" ? `₹ eg. 4500` : `₹ eg. 150`}
          slotProps={{
            input: {
              component: NumericFormatAdapter,
            },
          }}
        />
      </FormControl>
    </>
  );
};

export default FieldAmountPrice;
