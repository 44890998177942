import { Button } from "@mui/joy";
import OpenInNew from "@mui/icons-material/OpenInNew";
import useDashboardData from "../hooks/useDashboardData";
import { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Option,
  Radio,
  RadioGroup,
  Select,
} from "@mui/joy";

const DashboardHeader = ({ name = "", hideButtons = "false", onChange }) => {
  const [formData, setFormData] = useState({
    sector: "",
    cartType: "",
  });
  const { sector, setCurrentSector, setRouteName } = useDashboardData();
  const navigateRouteName = sector?.toLowerCase();

  useEffect(() => {
    if (onChange) {
        onChange(sector, formData.cartType);
      }
   
  }, [sector]);

  const handleChange = (e) => {
    const {name, value} = e.target
    console.log({name, value});
    setFormData({
      ...formData,
      cartType: value,
    });
    onChange(sector, value);
  };

  const setSector = async (value) => {
    setCurrentSector(value);
  };

  return (
    <>
      <nav className="level">
        <div className="level-left title">
          {name && name}
          {!name && (!sector ? "Choose Sector" : sector)}
          {hideButtons === "false" && sector !== "" ? (
            <Button
              className="ml-2"
              component="a"
              size="sm"
              variant="outlined"
              color="neutral"
              href={`/app/${navigateRouteName}`}
              startDecorator={<OpenInNew />}
            >
              {sector && ` Open ${sector}`}
            </Button>
          ) : null}
        </div>
      </nav>
      <div>
        {hideButtons === "false" ? (
          <>
            <div className="level">
              <div className="level-left">
                <p class="buttons">
                  <button
                    class={
                      sector === "Medicines" ? "button is-success" : "button"
                    }
                    onClick={() => setSector("Medicines")}
                  >
                    {sector === "Medicines" && (
                      <span class="icon is-small">
                        <i class="fas fa-check"></i>
                      </span>
                    )}
                    <span>Medicines</span>
                  </button>
                  <button
                    class={
                      sector === "Payments" ? "button is-success" : "button"
                    }
                    onClick={() => setSector("Payments")}
                  >
                    {sector === "Payments" && (
                      <span class="icon is-small">
                        <i class="fas fa-check"></i>
                      </span>
                    )}
                    <span>Payments</span>
                  </button>
                  <button
                    class={sector === "Foods" ? "button is-success" : "button"}
                    onClick={() => setSector("Foods")}
                  >
                    {sector === "Foods" && (
                      <span class="icon is-small">
                        <i class="fas fa-check"></i>
                      </span>
                    )}
                    <span>Foods</span>
                  </button>
                  <button
                    class={
                      sector === "Grocery" ? "button is-success" : "button"
                    }
                    onClick={() => setSector("Grocery")}
                  >
                    {sector === "Grocery" && (
                      <span class="icon is-small">
                        <i class="fas fa-check"></i>
                      </span>
                    )}
                    <span>Grocery</span>
                  </button>
                </p>
              </div>
            </div>
            <div className="level">
              <div className="level-left">
                {/* <FormControl
                  className="mb-2 mr-4"
                  style={{
                    width: "13em",
                  }}
                >
                  <FormLabel>Cart Type</FormLabel>
                  <RadioGroup
                    style={{
                      display: "inline-flex",
                      width: "fit-content",
                    }}
                    orientation="horizontal"
                    // defaultValue={formData.cartType}
                    name="radio-buttons-group"
                  >
                    <Radio
                      onChange={handleChange}
                      label="Personal"
                      name="cartType"
                      value="Personal"
                      variant="outlined"
                    />
                    <Radio
                      onChange={handleChange}
                      label="Family"
                      name="cartType"
                      value="Family"
                      variant="outlined"
                    />
                    <Radio
                      onChange={handleChange}
                      label="Emergency"
                      name="cartType"
                      value="Emergency"
                      variant="outlined"
                    />
                    <Radio
                      onChange={handleChange}
                      label="Gift"
                      name="cartType"
                      value="Gift"
                      variant="outlined"
                    />
                    <Radio
                      onChange={handleChange}
                      label="Occasion"
                      name="cartType"
                      value="Occasion"
                      variant="outlined"
                    />
                  </RadioGroup>
                </FormControl> */}
              </div>
            </div>
          </>
        ) : null}

        <hr></hr>
      </div>
    </>
  );
};

export default DashboardHeader;
