import { Container } from "@mui/material";
import FieldText from "./generic/FieldText";
import { Button, Divider } from "@mui/joy";
import FieldSelect from "./generic/FieldSelect";
import FieldAmountPrice from "./generic/FieldAmountPrice";
import FieldDate from "./generic/FieldDate";
import DashboardHeader from "./Dashboard/DashboardHeader";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addItem } from "../API";

//label, placeholder, helper_text, onChange
const AddForm = () => {
  const location = useLocation();
  const addFromSector = location?.state?.add;
  console.log({ addFromSector });
  const [sector, setSector] = useState(addFromSector ?? "");
  const [formData, setFormData] = useState({
    sector: "",
    cartType: "",
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      sector: `${sector}`,
    });
  }, [sector]);

  const navigate = useNavigate();

  const handleChange = (name, value, isSector) => {
    console.log({ name, value, isSector });
    setFormData({
      ...formData,
      [name]: value,
    });
    if (isSector) {
      setSector(value);
    }
  };

  const handleTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    console.log({
      ...formData,
      sector: formData.sector.toLowerCase(),
    });

    // make sector smallCase

    const result = await addItem({
      ...formData,
      sector: formData.sector.toLowerCase(),
    });
    if (result.success) {
      navigate(`/app/${formData.sector}`);
    }
  };

  console.log({ addFromSector, sector, formdata: formData.sector });
  return (
    <>
      <Container maxWidth="sm" className="is-flex-desktop-only">
        <div className="level">
          <div className="level-left">
            <DashboardHeader name="Add Item (new)" hideButtons="true" />
          </div>
          {sector === "fromDashboard" && (
            <div className="level-right">
              <DashboardHeader hideButtons="true" />
            </div>
          )}
          {sector !== "fromDashboard" && (
            <div className="level-right">
              <DashboardHeader
                name={
                  !sector
                    ? addFromSector === "fromDashboard"
                      ? "Choose Sector"
                      : `[ ${addFromSector} ]`
                    : `[ ${sector} ]`
                }
                hideButtons="true"
              />
            </div>
          )}
        </div>
        <form>
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-3">
              <div class="tile">
                <div class="tile is-parent">
                  <section className="mt-6">
                    <div class="tile is-ancestor">
                      <div class="tile is-vertical is-4">
                        <div class="tile">
                          <div class="tile is-parent">
                            {addFromSector === "fromDashboard" && (
                              <FieldSelect
                                label="Sector"
                                field_options="sector"
                                name="sector"
                                value={formData.sector}
                                onChange={(name, value) =>
                                  handleChange(name, value, "sector")
                                }
                              />
                            )}
                            <FieldSelect
                              label="Cart Type"
                              field_options="cartType"
                              name="cartType"
                              value={formData.cartType}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div class="tile">
                          <div class="tile is-parent">
                            <FieldText
                              label={
                                formData.sector === "Payments"
                                  ? "Card/Bank/EMI Name"
                                  : "Item Name"
                              }
                              placeholder="eg. what you prefer"
                              helper_text="try using upto 3 words"
                              name="one"
                              value={formData.one}
                              onChange={handleTextChange}
                            />
                            <FieldText
                              label={
                                formData.sector === "Payments"
                                  ? "Card/Bank/EMI Number"
                                  : "Brand Name"
                              }
                              placeholder={
                                formData.sector === "Payments"
                                  ? "eg. ending with 9907"
                                  : "platform for ordering"
                              }
                              helper_text="try using upto 2 words"
                              name="two"
                              value={formData.two}
                              onChange={handleTextChange}
                            />
                          </div>
                        </div>
                        <div class="tile">
                          <div class="tile is-parent">
                            <FieldSelect
                              name="three"
                              value={formData.three}
                              label={
                                formData.sector !== "Payments" && "Quantity"
                              }
                              field_options={
                                formData.sector === "Payments"
                                  ? "paymentsPendingDues"
                                  : "qty"
                              }
                              onChange={handleChange}
                            />
                            {formData.sector === "Payments" ? (
                              <FieldAmountPrice
                                name="four"
                                value={formData.four}
                                onChange={handleTextChange}
                                sector="Payments"
                              />
                            ) : (
                              <FieldAmountPrice
                                name="four"
                                value={formData.four}
                                onChange={handleTextChange}
                              />
                            )}
                          </div>
                        </div>
                        <div class="tile">
                          <div class="tile is-parent">
                            <FieldSelect
                              name="five"
                              value={formData.five}
                              label="Priority"
                              field_options="priority"
                              onChange={handleChange}
                            />
                            <FieldDate
                              label="Due Date"
                              name="six"
                              value={formData.six}
                              onChange={handleTextChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="mb-4"
            onClick={handleSubmit}
            disabled={
              formData.sector === "" ||
              formData.cartType === "" ||
              formData.one === "" ||
              formData.two === "" ||
              formData.three === "" ||
              formData.four === "" ||
              formData.five === "" ||
              formData.six === ""
            }
          >
            ADD
          </Button>
        </form>
      </Container>
    </>
  );
};

export default AddForm;
