// src/App.js
import React from "react";
import "./App.css";
import MainView from "./components/main";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginForm from "./components/auth/Login";
import MainHome from "./components/Home";
import { BrowserRouter as Router } from "react-router-dom";
import "@fontsource/inter";
import Dashboard from "./components/Dashboard/index";
import Grocery from "./components/sectors";
import AddForm from "./components/AddForm";
import Sector from "./components/sectors";
import useDashboardData from "./components/hooks/useDashboardData";
import UserAccount from "./components/UserAccount";
import LandingHome from "./components/LandingHome";
import MessagesFormat from "./components/MessagesFormat";
import PrivacyPolicy from "./components/LandingHome/PrivacyPolicy";

function App() {
  const { routeName } = useDashboardData();

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginForm />} />
          <Route exact path="/home" element={<LandingHome />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/app" element={<MainView />}>
            <Route index element={<Dashboard />} />
            <Route exact path="/app/:sector" element={<Sector />} />
            <Route exact path="add-item" element={<AddForm />} />
            <Route exact path="user" element={<UserAccount />} />
            <Route exact path="messages-format" element={<MessagesFormat />} />
            {/* <Route index element={<MainHome />} /> */}
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
