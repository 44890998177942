import { Paper } from "@mui/material";
import DashboardAnalyticsCard from "./DashboardAnalyticsCard";
import ItemCard from "../ItemCard";
import useDashboardData from "../hooks/useDashboardData";
import { useLocation } from "react-router-dom";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";

const DashboardOutlet = ({ sector, sectorData, cartType }) => {
  // Function to split the array into two arrays of 5 items each
  const splitItems = (array) => {
    if (array) {
      const middleIndex = Math.ceil(array.length / 2);
      const firstSet = array.slice(0, middleIndex);
      const secondSet = array.slice(middleIndex);
      return [firstSet, secondSet];
    }

    return [[], []];
  };

  // Check if sectorData?.mostRecentItems is not null or undefined
  const mostRecentItemsList = sectorData?.mostRecentItems || [];

  // Split the items array into two arrays
  const [firstSet, secondSet] = splitItems(mostRecentItemsList);

  const splitPrioritiesItems = (array) => {
    if (array) {
      const middleIndex = Math.ceil(array.length / 2);
      const firstPrioritySet = array.slice(0, middleIndex);
      const secondPrioritySet = array.slice(middleIndex);
      return [firstPrioritySet, secondPrioritySet];
    }

    return [[], []];
  };

  // Check if sectorData?.highPriorities is not null or undefined
  const highPriorities = sectorData?.highPriorities || [];

  const [firstPrioritySet, secondPrioritySet] =
    splitPrioritiesItems(highPriorities);

  function filterDate(date) {
    const dateTimeString = date;
    const dueDate = dateTimeString.split(" ")[0];
    return dueDate;
  }

  return (
    <>
      <div>
        <Paper
          style={{
            padding: "1em",
            border: "1px solid rgb(250, 250, 250)",
            boxShadow: "4px 12px 40px 6px rgba(0,0,0,0.09)",
            borderRadius: "1em",
          }}
        >
          <div class="tile is-ancestor">
            <div class="tile is-vertical is-3">
              <div class="tile">
                <div class="tile is-parent">
                  <DashboardAnalyticsCard
                    perc="40"
                    dcard_title="Total Items"
                    numeric_value={sectorData.totalCount}
                    page={sector}
                    amount="false"
                    dcard_subtitle="pending/completed items"
                    pending="true"
                    sector={sector && sector}
                  />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-2">
              <div class="tile">
                <div class="tile is-parent">
                  <DashboardAnalyticsCard
                    size="13em"
                    perc="60"
                    dcard_title="Pending"
                    numeric_value={sectorData.pendingItems}
                    amount="false"
                    page={sector}
                    dcard_subtitle="items"
                    pending="true"
                    sector={sector && sector}
                  />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-2">
              <div class="tile">
                <div class="tile is-parent">
                  <DashboardAnalyticsCard
                    size="13em"
                    perc="60"
                    dcard_title="Completed"
                    numeric_value={sectorData.completedItems}
                    amount="false"
                    page={sector}
                    dcard_subtitle="items"
                    pending="true"
                    sector={sector && sector}
                  />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-2">
              <div class="tile">
                <div class="tile is-parent">
                  <DashboardAnalyticsCard
                    size="14em"
                    perc="60"
                    dcard_title="Expenses"
                    numeric_value={sectorData.expenseSpent}
                    amount="true"
                    page={sector}
                    dcard_subtitle="spent"
                    pending="true"
                    sector={sector && sector}
                  />
                </div>
              </div>
            </div>
            <div class="tile is-vertical is-2">
              <div class="tile">
                <div class="tile is-parent">
                  <DashboardAnalyticsCard
                    size="14em"
                    perc="60"
                    dcard_title="Expenses"
                    numeric_value={sectorData.expenseRemaining}
                    amount="true"
                    page={sector}
                    dcard_subtitle="remaining"
                    pending="true"
                    sector={sector && sector}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>

        {/* ITEMS BASED ON MOST RECENTS AND PRIORITES  */}
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-3">
            {/*  Most Recents */}
            <div class="tile">
              <div class="tile is-parent">
                <section className="mt-6">
                  <div className="title">Most Recents</div>
                  <div class="tile is-ancestor">
                    <div class="tile is-vertical is-4">
                      <div class="tile mb-5">
                        {firstSet.length === 0 && (
                          <div
                            style={{
                              margin: "1em 0em 0em 0em",
                              fontSize: "2em",
                              border: "1px solid lightgray",
                              padding: "0.5em",
                              color: "lightgray",
                              width: "max-content",
                            }}
                          >
                            <SentimentDissatisfiedOutlinedIcon /> Choose Sector
                          </div>
                        )}
                        {firstSet &&
                          firstSet.map((item) => {
                            return (
                              <div class="tile is-parent">
                                <ItemCard
                                  product={item.one}
                                  brand={item.two}
                                  price={item.four}
                                  orderBy={filterDate(item.six)}
                                  qtyDues={item.three}
                                  sector={sector}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="tile">
                        {secondSet &&
                          secondSet.map((item) => {
                            return (
                              <div class="tile is-parent">
                                <ItemCard
                                  product={item.one}
                                  brand={item.two}
                                  price={item.four}
                                  orderBy={filterDate(item.six)}
                                  qtyDues={item.three}
                                  sector={sector}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {/* Priorities */}
            <div class="tile">
              <div class="tile is-parent">
                <section className="mt-6">
                  <div className="title">High Priorities</div>
                  <div class="tile is-ancestor">
                    <div class="tile is-vertical is-4">
                      <div class="tile">
                        {!sectorData.highPriorities && (
                          <div
                            style={{
                              margin: "1em 0em 0em 0em",
                              fontSize: "2em",
                              border: "1px solid lightgray",
                              padding: "0.5em",
                              color: "lightgray",
                              width: "max-content",
                            }}
                          >
                            <SentimentDissatisfiedOutlinedIcon /> Choose Sector
                          </div>
                        )}
                        {firstPrioritySet &&
                          firstPrioritySet.map((item) => {
                            return (
                              <div class="tile is-parent">
                                <ItemCard
                                  product={item.one}
                                  brand={item.two}
                                  price={item.four}
                                  orderBy={filterDate(item.six)}
                                  qtyDues={item.three}
                                  sector={sector}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div class="tile">
                        {secondPrioritySet &&
                          secondPrioritySet.map((item) => {
                            return (
                              <div class="tile is-parent">
                                <ItemCard
                                  product={item.one}
                                  brand={item.two}
                                  price={item.four}
                                  orderBy={filterDate(item.six)}
                                  qtyDues={item.three}
                                  sector={sector}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardOutlet;
