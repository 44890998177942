import * as React from "react";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import { useNavigate } from "react-router-dom";

// page = string of route
// perc = percentage of specific card
// dcard_title = title of the card on dashboard
// numeric_value = amount or value of the progress or percentage
// dcard_subtitle = description of the title and card
// amount = value must be true/false     if y show currency
// pending = true/false on true card must display pending items or else display completed items

export default function DashboardAnalyticsCard({
  perc,
  dcard_title,
  numeric_value,
  page,
  dcard_subtitle,
  amount,
  pending,
  size,
  sector,
}) {
  const navigate = useNavigate();
  const takeMeTo = (navigation) => {
    navigate(`/app/${navigation}`);
  };
  return (
    <Card
      variant="solid"
      color={dcard_title === "Expenses" ? "success" : "primary"}
      invertedColors
      style={{
        width: size ? size : "inherit",
      }}
    >
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" determinate value={perc}>
          <SvgIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
              />
            </svg>
          </SvgIcon>
        </CircularProgress>
        <CardContent>
          <Typography level="body-md">{dcard_title}</Typography>
          <Typography level="h2">
            {amount === "true" ? "₹" : ""} {numeric_value}
          </Typography>
          <Typography level="body-md">{dcard_subtitle}</Typography>
        </CardContent>
      </CardContent>
      <CardActions>
        <Button variant="soft" size="sm" onClick={() => takeMeTo(sector)}>
          Show Detailed
        </Button>
        {/* <Button variant="solid" size="sm">
          See breakdown
        </Button> */}
      </CardActions>
    </Card>
  );
}
