import { useEffect, useState } from "react";

// 0 for logged out
// 1 logged in

const useCheckLoginStatus = () => {
  const [status, setStatus] = useState(null);
  

  function setLoginStatus(code) {
   setStatus(code)
  }

  return {
   status,
   setLoginStatus
  };
};

export default useCheckLoginStatus;
