export async function loginClient(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/auth/eazycarts/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // credentials: true,
        body: JSON.stringify(formData),
      }
    );
    const result = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        return new Promise((resolve) => {
          resolve({ data: result });
        }).then((res) => {
          return {
            success: false,
            data: 401,
          };
        });
        return {
          success: false,
          data: 401,
        };
      } else if (response.status === 404) {
        return new Promise((resolve) => {
          resolve({ data: result });
        }).then((res) => {
          return {
            success: false,
            data: 404,
          };
        });
      }
      const res = await response.json();
      return { success: false, data: res.message };
    }

    if (response.status === 200 && result) {
      return new Promise((resolve) => {
        localStorage.setItem("customer", result.customer);
        resolve({ data: result });
      }).then((res) => {
        return {
          success: true,
          data: res.data,
        };
      });
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function signUpUser(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/onboard",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // credentials: true,
        body: JSON.stringify(formData),
      }
    );
    const result = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        return new Promise((resolve) => {
          resolve({ data: result });
        }).then((res) => {
          return {
            success: false,
            data: 401,
          };
        });
        return {
          success: false,
          data: 401,
        };
      } else if (response.status === 404) {
        return new Promise((resolve) => {
          resolve({ data: result });
        }).then((res) => {
          return {
            success: false,
            data: 404,
          };
        });
      }
      const res = await response.json();
      return { success: false, data: res.message };
    }

    if (response.status === 200 && result) {
      return new Promise((resolve) => {
        resolve({ data: result });
      }).then((res) => {
        return {
          success: true,
          data: res.data,
        };
      });
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function Logout() {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/auth/clients/logout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();

    if (response.status === 200 && result) {
      return new Promise((resolve) => {
        resolve({ data: result });
      }).then((res) => {
        document.cookie =
          "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return {
          success: true,
          message: "Logout successful!",
        };
      });
      document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.reload();
      return {
        success: true,
      };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function getDashboard(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/dashboard",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();

    return {
      success: true,
      data: result,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function itemCompleted(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/items/completed",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();

    return {
      success: true,
      data: result,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function addItem(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/items/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();

    return {
      success: true,
      data: result,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function getItems(formData) {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/items/list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // withCredentials: true,
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();
    console.log({ result });
    return {
      success: true,
      data: result,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function getUserAccount() {
  try {
    const response = await fetch(
      "https://amethyst-adder-hem.cyclic.cloud/api/eazycarts/user",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      return {
        success: false,
      };
    }

    const result = await response.json();

    return {
      success: true,
      data: result?.user,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
}
