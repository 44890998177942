import React, { useEffect, useState } from "react";
import { Container, Paper, TextField } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import useCheckIsLoggedIn from "../hooks/useCheckLoggedIn";
import useCheckToken from "../hooks/useCheckToken";
import { loginClient } from "../../API";
import useCheckLoginStatus from "../hooks/useCheckLoginStatus";
import MainView from "../main";
import Logo from "../../images/logo.png";
// import Logo from "../../../src/images/logoTEMP.png";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import { Sheet } from "@mui/joy";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonText, setButtonText] = useState("Login");

  const { isLogged, setIsLogged } = useCheckIsLoggedIn();
  const { isValid } = useCheckToken();
  const { status, setLoginStatus } = useCheckLoginStatus();

  const navigate = useNavigate();

  if (isValid === 1) {
    return <MainView />;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setButtonText("Logging in");
    const reqBody = {
      email: username,
      password,
    };
    const result = await loginClient(reqBody);

    if (!result?.success && result?.data === 401) {
      setButtonText("Login");
      setLoginStatus(401);
      setIsLogged(0);
    } else if (!result?.success && result?.data === 404) {
      setButtonText("Login");
      setLoginStatus(404);
      setIsLogged(0);
    }
    if (result?.success) {
      // localStorage.setItem("euser", result?.data?.customer_name);
      // Check if the user is using Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        alert("As of now, we recommed Chrome Browser only, Thanks :)");
      }

      // Check if the user is using Chrome
      const isChrome = /chrome/i.test(navigator.userAgent);

      if (isSafari) {
        //  setloca
      }
      setIsLogged(1);
      navigate("/app");
      window.location.href = "/app";
      // setLoginStatus(200);
    }
  };

  return (
    <>
      <img
        src={Logo}
        style={{
          width: "18em",
          display: "flex",
          justifyContent: "center",
          margin: "2em auto 10em auto",
        }}
      />
      <Sheet
        sx={{
          width: 300,
          mx: "auto", // margin left & right
          my: 4, // margin top & bottom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
        }}
        variant="outlined"
      >
        <div>
          <Typography level="h4" component="h1">
            <b>Welcome!</b>
          </Typography>
          <Typography level="body-sm">Sign in to continue.</Typography>
        </div>
        {status && status === 200 && ""}
        {status && status === 401 && (
          <p style={{ color: "red" }}>
            Incorrect email or password, try again!
          </p>
        )}
        {status && status === 404 && (
          <p style={{ color: "red" }}>Account doesn't exist!</p>
        )}
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            // html input attribute
            name="Email"
            type="email"
            placeholder="email@domain.com"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            // html input attribute
            name="Password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button
          sx={{ mt: 1 /* margin top */ }}
          onClick={handleLogin}
          disabled={username === "" || password === "" ? true : false}
        >
          Log in
        </Button>
      </Sheet>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          fontSize: "12px",
        }}
      >
        <Typography level="body-sm">
          <b>New User? </b>{" "}
          <a href="https://www.eazycarts.online/home">
            <u>Sign Up</u>
          </a>
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            // bottom: "0",
            position: "absolute",
            width: "100%",
            fontSize: "12px",
            padding: "3em 0 6em 0",
          }}
        >
          Copyright © 2024 All rights reserved
        </span>
      </div>
    </>
    // <Container
    //   maxWidth="md"
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "71vh",
    //     marginTop: "-3em",
    //   }}
    // >
    //   <img src={Logo} />
    //   <Paper style={{ padding: "2rem", width: "400px" }}>
    //     <Typography variant="h5" component="h2" gutterBottom>
    //       EAZYCART.online
    //     </Typography>
    //     <br></br>
    //     {status && status === 200 && ""}
    //     {status && status === 401 && (
    //       <p style={{ color: "red" }}>
    //         Incorrect email or password, try again!
    //       </p>
    //     )}
    //     {status && status === 404 && (
    //       <p style={{ color: "red" }}>Account doesn't exist!</p>
    //     )}

    //     <TextField
    //       label="Email"
    //       fullWidth
    //       margin="normal"
    //       variant="outlined"
    //       value={username}
    //       onChange={(e) => setUsername(e.target.value)}
    //     />
    //     <TextField
    //       label="Password"
    //       fullWidth
    //       margin="normal"
    //       variant="outlined"
    //       type="password"
    //       value={password}
    //       onChange={(e) => setPassword(e.target.value)}
    //     />
    //     <button
    //       onClick={handleLogin}
    //       disabled={username === "" || password === "" ? true : false}
    //     >
    //       {buttonText}
    //     </button>
    //   </Paper>
    // </Container>
  );
};

export default LoginForm;
