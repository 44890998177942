import { FormControl, FormLabel, Input, Option, Select } from "@mui/joy";

const FieldSelect = ({ name, label, field_options, value, onChange }) => {
  let displayField;
  let options = [];
  const selectName = name;
  if (field_options === "qty") {
    options = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10+", value: "10+" },
      { label: "20+", value: "20+" },
    ];
  } else if (field_options === "priority") {
    options = [
      { label: "High", value: "high" },
      { label: "Medium", value: "medium" },
      { label: "Low", value: "low" },
    ];
  } else if (field_options === "sector") {
    options = [
      { label: "Grocery", value: "Grocery" },
      { label: "Medicines", value: "Medicines" },
      { label: "Payments", value: "Payments" },
      { label: "Foods", value: "Foods" },
    ];
  } else if (field_options === "paymentsPendingDues") {
    options = [
      { label: "Current", value: "Current" },
      { label: "2", value: "2" },
      { label: "3", value: "2" },
      { label: "3+", value: "3+" },
    ];
  } else if (field_options === "cartType") {
    options = [
      { label: "Personal", value: "Personal" },
      { label: "Family", value: "Family" },
      { label: "Gift", value: "Gift" },
      { label: "Emergency", value: "Emergency" },
      { label: "Occasion", value: "Occasion" },
    ];
  } else if (field_options === "profession"){
    options = [
      { label: "Professional", value: "Professional" },
      { label: "Entrepreuner", value: "Entrepreuner" },
      { label: "Student", value: "Student" },
      { label: "Housewife", value: "Housewife" },
      { label: "Others", value: "Others" },
    ];
  }

  const handleChange = (name, value) => {
    onChange(selectName, value);
  };

  return (
    <>
      <FormControl
        className="mb-2 mr-4"
        style={{
          width: "13em",
        }}
      >
        <FormLabel>
          {field_options === "paymentsPendingDues" ? "Due months" : label}
        </FormLabel>
        <Select
          size="sm"
          placeholder="Choose one..."
          onChange={handleChange}
          style={{
            display: displayField,
          }}
          name={name}
          value={value}
        >
          {Array.isArray(options) &&
            options.length > 0 &&
            options.map((option) => {
              return (
                <Option
                  value={option.value}
                  style={{
                    color:
                      (option.value === "high" && "red") ||
                      (option.value === "medium" && "blue") ||
                      (option.value === "low" && "green"),
                  }}
                >
                  {option.label}
                </Option>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};

export default FieldSelect;
