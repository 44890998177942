import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { useState } from "react";

const FieldText = ({
  name,
  label,
  placeholder,
  helper_text,
  onChange,
  type,
}) => {
  const [input, setInput] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput(event.target.value);
    onChange(name, value);
  };

  return (
    <>
      <FormControl className="mb-2 mr-4">
        <FormLabel>{label}</FormLabel>
        <Input
          type={type ? type : "text"}
          name={name}
          value={input}
          placeholder={placeholder ? placeholder : "Enter Text here"}
          onChange={handleChange}
          required
        />
        <FormHelperText>{helper_text}</FormHelperText>
      </FormControl>
    </>
  );
};

export default FieldText;
