import { Container, FormControlLabel, FormLabel, Grid } from "@mui/material";
import Logo from "../../images/logo.png";
import MainBanner from "../../images/mainBanner.png";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import OpenInNew from "@mui/icons-material/OpenInNew";
import FeaturesOutlet from "./FeaturesOutlet";
import PricingCards from "./Pricing";
import Footer from "./Footer";
import FieldSelect from "../generic/FieldSelect";
import FieldText from "../generic/FieldText";
import { useState } from "react";
import { signUpUser } from "../../API";

const LandingHome = () => {
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    number: "",
    profession: "",
    password: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (name, value) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleTextChange = (name, value) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSignUp = async () => {
    const reqBody = {
      fullName: userData.fullName,
      email: userData.email,
      number: userData.number,
      professional: userData.profession,
      password: userData.password,
    };
    const result = await signUpUser(reqBody);
    if (result.success) {
      setIsSuccess(true);
      setUserData({
        fullName: "",
        email: "",
        number: "",
        profession: "",
        password: "",
      });
    } else {
      setIsSuccess(false);
      setUserData({
        fullName: "",
        email: "",
        number: "",
        profession: "",
        password: "",
      });
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <div>
          <Grid>
            <nav className="level mt-4 mb-6">
              <div className="level-left title">
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "14em" }}
                  // onClick={() => navigate("/app")}
                  className="LogoHover"
                />
              </div>
              <div className="level-right title">
                <Button
                  variant="soft"
                  className="mr-4"
                  onClick={() =>
                    (window.location.href = "https://www.eazycarts.online")
                  }
                >
                  Login
                </Button>
                <IconButton
                  aria-label="Open in new tab"
                  component="a"
                  onClick={() =>
                    document.getElementById("startedSection").scrollIntoView()
                  }
                >
                  Get Started&nbsp;
                  <OpenInNew />
                </IconButton>
              </div>
            </nav>
          </Grid>

          <div
            className="p-2"
            style={{
              marginTop: "14em",
            }}
          >
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "400",
                lineHeight: "1.125",
                textAlign: "center",
              }}
              className="title"
            >
              Simplify Your Shopping Experience with Eazycarts.<br></br>
              Streamline Grocery/Payment/Medicine/Food Carts Effortlessly.
            </h1>
            <h2 class="subtitle is-4 has-text-centered mb-6 mt-4">
              Say goodbye to tedious shopping lists and scattered payments.
            </h2>
          </div>
          <Container
            sx={{
              width: 300,
              mx: "auto", // margin left & right
              my: 4, // margin top & bottom
              py: 3, // padding top & bottom
              px: 2, // padding left & right
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              // boxShadow: "md",
            }}
          >
            <IconButton
              aria-label="Open in new tab"
              component="a"
              variant="outlined"
              onClick={() =>
                document.getElementById("startedSection").scrollIntoView()
              }
            >
              Get Started&nbsp;
              <OpenInNew />
            </IconButton>
          </Container>
          {/* <Sheet
          
          variant="outlined"
        ></Sheet> */}
          <Container
            sx={{
              // width: 300,
              mx: "auto", // margin left & right
              my: 4, // margin top & bottom
              py: 3, // padding top & bottom
              px: 2, // padding left & right
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: "sm",
              // boxShadow: "md",
            }}
          >
            <img
              src={MainBanner}
              alt="MainBanner"
              // onClick={() => navigate("/app")}
              className="LogoHover mb-6 mt-2"
            />{" "}
            <i style={{ margin: "0 auto" }}>"scroll down" </i>
          </Container>
        </div>
        <FeaturesOutlet />
        <Container
          sx={{
            // width: 300,
            mx: "auto", // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            // boxShadow: "md",
          }}
        >
          <section
            id="startedSection"
            style={{
              marginTop: "14em",
              marginBottom: "4em",
            }}
          >
            <h1
              style={{
                marginBottom: "2rem",
              }}
              class="title is-1 mt-6 text-centered has-text-centered has-text-weight-bold"
            >
              Let's Get Started - It's free
            </h1>
            <h2
              class="subtitle is-2 has-text-centered"
              style={{ marginBottom: "4rem" }}
            >
              Discover Today and Take Control of Your Carts!
              <br></br>
              <br></br>
              <strong
                className="underline"
                style={{
                  fontSize: "2em",
                }}
              >
                ~simple as saved for later!
              </strong>
            </h2>
            <br></br>
            <Sheet
              sx={{
                width: 300,
                mx: "auto", // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: "sm",
                boxShadow: "md",
              }}
              variant="outlined"
            >
              {!isSuccess && (
                <>
                  <FormControl>
                    <FieldSelect
                      name="profession"
                      value={userData.profession}
                      label="Profession"
                      field_options="profession"
                      onChange={(name, value) => handleChange(name, value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FieldText
                      label="Full Name"
                      placeholder="First & Last Name"
                      name="fullName"
                      value={userData.fullName}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FieldText
                      label="Email"
                      placeholder="email@gmail.com"
                      name="email"
                      value={userData.email}
                      type={"email"}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FieldText
                      label="Number"
                      placeholder="1234567890"
                      // helper_text={"10 digits only"}
                      name="number"
                      value={userData.number}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FieldText
                      label="Password"
                      placeholder="******"
                      name="password"
                      type="password"
                      value={userData.password}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                  <Button
                    sx={{ mt: 1 /* margin top */ }}
                    onClick={handleSignUp}
                    disabled={
                      !userData.email ||
                      !userData.fullName ||
                      !userData.number ||
                      !userData.password ||
                      !userData.profession
                    }
                  >
                    Sign up
                  </Button>
                </>
              )}
              {isSuccess && (
                <>
                  <Typography level="h4" component="h1">
                    <b>Thanks!</b>
                  </Typography>
                  <Typography level="body-sm">
                    Now you can login to your new account.
                  </Typography>
                </>
              )}
            </Sheet>
            {/* <nav
              className="level mt-4 mb-6"
              style={{
                margin: "0 auto",
                width: "70%",
              }}
            >
              <PricingCards />
            </nav> */}
          </section>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default LandingHome;
