import { Button, ToggleButtonGroup } from "@mui/joy";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useSectorData from "../hooks/useSectorData";
import { useEffect, useState } from "react";
import SectorOutlet from "./SectorOutlet";
import { useNavigate } from "react-router-dom";
import FieldDate from "../generic/FieldDate";
const SectorHeader = ({ name }) => {
  const { sector, setCurrentSector, filter, setCurrentFilter } =
    useSectorData();
  const [value, setValue] = useState("");
  const [priority, setPriority] = useState("");
  const filters = ["All Items", "Pending", "Completed", "Missed"];
  const [filterLoading, setFilterLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentSector(name);
  }, []);

  const setPriorityButton = (newValue) => {
    if (newValue === null) {
      setPriority("");
    }
    setValue(newValue);
    if (newValue === "sm") {
      setPriority("low");
    } else if (newValue === "md") {
      setPriority("medium");
    } else if (newValue === "lg") {
      setPriority("high");
    }
  };

  const handleFilters = (filter) => {
    setActiveFilter(filter);
    setFilterLoading(true);
    setCurrentFilter(filter);

    // setTimeout(() => {
    //   setFilterLoading(false);
    // }, 500);
  };

  const handleFilterDate = (name, value) => {
    console.log({ name, value });

    setFormData({
      ...formData,
      [name]: value,
    });
    console.log();
  };

  return (
    <>
      <nav className="level">
        <div className="level-left title">{sector}</div>
      </nav>
      <div>
        <div className="level">
          <div className="level-left">
            <p class="buttons">
              {Array.isArray(filters) &&
                filters.map((filter) => {
                  return (
                    <button
                      class="button"
                      onClick={() => handleFilters(filter)}
                      style={{
                        background:
                          activeFilter === filter ? "gainsboro" : null,
                        color: setActiveFilter === filter ? "grey" : null,
                      }}
                    >
                      <span>{filter}</span>
                    </button>
                  );
                })}

              {/* <button class="button  is-success">
                  <span class="icon is-small">
                    <i class="fas fa-check"></i>
                  </span>
                  <span>Grocery</span>
                </button> */}
            </p>
          </div>
          <div
            className="level-right has-text-centered"
            style={{
              height: "3em",
              borderRadius: "7px",
              padding: "0 1em",
              //   border: "1px solid lightgrey",
            }}
          >
            {/* NO FILTER MAGIC APPLIED */}
            <ToggleButtonGroup
              size={value || undefined}
              value={value}
              onChange={(event, newValue) => {
                setPriorityButton(newValue);
              }}
            >
              <span
                style={{
                  margin: "auto",
                  fontSize: "1.2em",
                }}
              >
                Priority&nbsp;
              </span>
              <Button
                value="sm"
                style={{
                  background: value === "sm" ? "lightgreen" : "gainsboro",
                  color: "grey",
                }}
              >
                Low
              </Button>
              <Button
                value="md"
                style={{
                  background: value === "md" ? "lightsalmon" : "gainsboro",
                  color: "grey",
                }}
              >
                Medium
              </Button>
              <Button
                value="lg"
                style={{
                  background: value === "lg" ? "lightcoral" : "gainsboro",
                  color: "grey",
                }}
              >
                High
              </Button>
            </ToggleButtonGroup>
          </div>
        </div>

        {/* <h1 class="title is-4rem mb-0">
            Current<br></br>All Items
          </h1> */}
        <hr></hr>
        <div className="level">
          <div className="level-left">
            <h2
              class="subtitle is-4rem mb-6"
              style={{ marginBottom: "2rem", marginRight: "4em" }}
            >
              Try <strong>Priority</strong> to filter items:
            </h2>
            <br></br>
            <FieldDate
              name="startDate"
              label="Start Date"
              value={formData.startDate}
              onChange={handleFilterDate}
            />

            <FieldDate
              name="endDate"
              label="End Date"
              value={formData.endDate}
              onChange={handleFilterDate}
            />
          </div>
          <div className="level-right">
            <div
              className="level-left has-text-centered"
              style={
                {
                  // background: "aliceblue",
                  // width: "48em",
                  // height: "3em",
                  // borderRadius: "1em",
                }
              }
            >
              <Button
                startDecorator={<AddCircleOutlineOutlinedIcon />}
                onClick={() =>
                  navigate("/app/add-item", {
                    state: {
                      add: `${sector}`,
                    },
                  })
                }
              >
                Add Item
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* {filterLoading ? (
        <Button
          loading
          variant="plain"
          loadingPosition="start"
          style={{
            margin: "0 auto",
            display: "block",
            padding: "7em 1em",
          }}
        >
          Loading data...
        </Button>
      ) : (
        <SectorOutlet filter={filter} sector={sector}/>
      )} */}
      <SectorOutlet
        filter={filter}
        sector={sector}
        priority={priority}
        startDate={formData.startDate}
        endDate={formData.endDate}
      />
    </>
  );
};

export default SectorHeader;
