import { useState } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardOutlet from "./DashboardOutlet";
import { getDashboard } from "../../API";

const Dashboard = () => {
  const [selectedSector, setSelectedSector] = useState("");
  const [cartType, setCartType] = useState("");
  const [selectedSectorData, setSelectedSectorData] = useState({
    totalCount: 0,
    pendingItems: 0,
    completedItems: 0,
    expenseSpent: 0,
    expenseRemaining: 0,
    mostRecentItems: [],
  });

  const handleChange = async (sector, cartType) => {
    setSelectedSector(sector);
    setCartType(cartType);
    const sectorName = sector.toLowerCase();
    if (sectorName) {
      const data = await getDashboard({ sector: sectorName });
      console.log(data.data);
      if (data.data) {
        setSelectedSectorData({
          ...selectedSectorData,
          totalCount: data.data.totalCount,
          pendingItems: data.data.pendingItems,
          completedItems: data.data.completedItems,
          expenseSpent: data.data.expenseSpent,
          expenseRemaining: data.data.expenseRemaining,
          mostRecentItems: data.data.mostRecentItems,
          highPriorities: data.data.highPriorities,
        });
      }

      console.log({ selectedSectorData });
    }
  };

  return (
    <>
      <DashboardHeader onChange={handleChange} />
      <DashboardOutlet
        cartType={cartType}
        sector={selectedSector}
        sectorData={selectedSectorData}
      />
    </>
  );
};

export default Dashboard;
