import { useEffect, useState } from "react";

const useDashboardData = () => {
  const [sector, setSector] = useState("");
  const [routeName, setRoute] = useState("");

  const setCurrentSector = (name) => {
    setSector(name);
  };

  const setRouteName = (name) => {
    setRoute(name);
  };

  return {
    sector,
    setCurrentSector,
    setRouteName,
    routeName,
  };
};

export default useDashboardData;
