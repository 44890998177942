import { useEffect, useState } from "react";

const useSectorData = () => {
  const [sector, setSector] = useState("");
  const [filter, setFilter] = useState("");

  const setCurrentSector = (name) => {
    setSector(name);
  };

  const setCurrentFilter = (name) => {
    setFilter(name);
  };

  return {
    sector,
    setCurrentSector,
    filter,
    setCurrentFilter,
  };
};

export default useSectorData;
